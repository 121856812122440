import React from 'react'

const Navbar = ({ page, setPage }) => {
  return (
    <nav className="mb-5">
      <button
        className={`btn btn-lg ${
          page === 'people' ? 'btn-info' : 'btn-outline-info'
        } rounded-pill px-4 me-3`}
        onClick={() => setPage('people')}
      >
        People
      </button>
      <button
        className={`btn btn-lg ${
          page === 'planets' ? 'btn-info' : 'btn-outline-info'
        } rounded-pill px-4`}
        onClick={() => setPage('planets')}
      >
        Planets
      </button>
    </nav>
  )
}

export default Navbar
