import React from 'react'

const Person = ({ person }) => {
  return (
    <div className="card shadow-lg">
      <div className="card-body">
        <h3 className="fw-semibold text-info">{person.name}</h3>
        <p>Gender - {person.gender}</p>
        <p className="mb-0">Birth year - {person.birth_year}</p>
      </div>
    </div>
  )
}

export default Person
