import React from 'react'

const Pagination = ({ data, page, setPage }) => {
  return (
    <>
      <button
        onClick={() => setPage((page) => page - 1)}
        className={`btn btn-lg-- btn-outline-secondary rounded-pill px-4 ${
          !data.previous && 'disabled'
        }`}
      >
        <span className="me-2">&#8249;</span>
        Previous
      </button>
      <span className="paging mx-3 fw-light">
        <span className="me-2 d-none d-sm-inline-block">Page</span>
        {page} /{' '}
        {Math.floor(data.count % 10)
          ? Math.floor(data.count / 10) + 1
          : Math.floor(data.count / 10)}
      </span>
      <button
        onClick={() => setPage((page) => page + 1)}
        className={`btn btn-lg-- btn-outline-secondary rounded-pill px-4 ${
          !data.next && 'disabled'
        }`}
      >
        Next<span className="ms-2">&#8250;</span>
      </button>
    </>
  )
}

export default Pagination
