import React from 'react'

const Planet = ({ planet }) => {
  return (
    <div className="card shadow-lg">
      <div className="card-body">
        <h3 className="fw-semibold text-info">{planet.name}</h3>
        <p>Population - {planet.population}</p>
        <p className="mb-0">Terrain - {planet.terrain}</p>
      </div>
    </div>
  )
}

export default Planet
