import React, { useState } from 'react'
import { useQuery } from 'react-query'

import Pagination from './Pagination'
import Planet from './Planet'

const fetchPlanets = async (page) => {
  const res = await fetch(`https://swapi.dev/api/planets?page=${page}`)
  return res.json()
}

const Planets = () => {
  const [page, setPage] = useState(1)
  const { isLoading, isError, data } = useQuery(['planets', page], () =>
    fetchPlanets(page)
  )

  if (isLoading)
    return (
      <div className="text-center">
        <div
          className="spinner spinner-border text-center text-info mx-auto"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  if (isError) return <div className="text-center">Error fetching data!</div>

  return (
    <div>
      <h2 className="mb-3">Planets</h2>
      <div className="mt-5 mb-4">
        <Pagination data={data} page={page} setPage={setPage} />
      </div>
      <div>
        {data.results.map((planet) => (
          <Planet planet={planet} key={planet.name} />
        ))}
      </div>
      <div className="mt-4 mb-4">
        <Pagination data={data} page={page} setPage={setPage} />
      </div>
    </div>
  )
}

export default Planets
