import React, { useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import Footer from './components/Footer'
import Navbar from './components/Navbar'
import Planets from './components/Planets'
import People from './components/People'

const queryClient = new QueryClient()

function App() {
  const [page, setPage] = useState('planets')

  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <div className="container mt-4 mt-md-5">
        <div className="text-center">
          <h1 className="fw-semibold pt-2 pt-md-0 mb-4">Star Wars Info</h1>
          <Navbar page={page} setPage={setPage} />
        </div>
        <main>{page === 'planets' ? <Planets /> : <People />}</main>
        <Footer />
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
