import React from 'react'

const Footer = () => {
  return (
    <footer>
      {/* <a
            href="https://youcefdeveloper.com"
            target="_blank"
            rel="noreferrer"
            className="text-info"
          > */}
      Youcef Developer &copy; 2021
      {/* </a> */}
      <div className="mt-4">
        <div className="small mb-2">Technologies</div>
        <small className="badge bg-secondary rounded-pill">Javascript</small>
        <small className="badge bg-secondary rounded-pill">React</small>
        <small className="badge bg-secondary rounded-pill">React Query</small>
        <small className="badge bg-secondary rounded-pill">REST API</small>
        <small className="badge bg-secondary rounded-pill">Netlify</small>
      </div>
    </footer>
  )
}

export default Footer
